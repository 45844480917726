import { register, fetchOneEntry,fetchEntries, getBuilderSearchParams } from "@builder.io/sdk-solid";

/**
 * Asserts the builder menu layout based on a component list.
 * @param components List of components to insert.
 */
export const generateBuilderSections = (components) => {
  const menu = components.reduce((memo, component) => {
    const section = component.section;
    if (!section) return memo;
    if (!memo[section]) memo[section] = { name: section, items: [] };
    memo[section].items.push({
      name: component.friendlyName || component.name,
      item: component.name,
    });
    return memo;
  }, {});
  register("editor.settings", {
    customElements: false,
    hideStyleTab: false, // Hide the style tab
    hideMainTabs: false, // Hide all main tabs
    hideDataTab: false, // Hide the data tab
    hideOptionsTab: false, // Hide the options tab
    hideToolbar: false, // Hide the main toolbar
    hideHeatMap: false, // Hide the heatmap button
    hidePageUrlEditor: false, // Hide the page URL editor
    hideAnimateTab: false, // Hide the animate tab
    hideInsertTab: false, // Hide the insert tab
    hideTargeting: false, // Hide the targeting UI
    hideHelpWidget: false, // Hide help widget
  });
  Object.values(menu).forEach((menu) => register("insertMenu", menu));
};

/**
 * Helper method to retrieve builder content.
 * @param path Path of the content to retrieve
 * @param model Model type to retrieve
 * @returns Builder page content
 */
export const getBuilderContent = async (
  path: string,
  model: string,
  includeUnpub: boolean = false
) => {
  const params = new URLSearchParams(path);
  let options = getBuilderSearchParams(params);

  return fetchOneEntry({
    model,
    apiKey: import.meta.env.VITE_BUILDER_APIKEY,
    options,
    userAttributes: {
      urlPath: path,
    },
    includeUnpublished: includeUnpub,
  });
};

export const getBuilderContentByModel = async (
  model: string,
  limit: number,
  offset: number,
  options?: object
) => {
  return fetchEntries({
    model,
    apiKey: import.meta.env.VITE_BUILDER_APIKEY,
    limit,
    offset,
    options,
  });
};



export const getBuilderContentById = async (
  id: string,
  model: string,
  key: string = "slug"
) => {
  return fetchOneEntry({
    model,
    apiKey: import.meta.env.VITE_BUILDER_APIKEY,
    query: {
      [`data.${key}.$eq`]: id,
    },
  });
};



export const builderImage = (obj: {
  src?: string;
  width?: number;
  height?: number;
  quality?: number;
  format?: string;
  fit?: "inside" | "fill" | "cover" | "outside" | "contain";
}): string => {
  const src = obj.src;
  delete obj.src;
  obj.format = "webp";
  return (
    `${src}?` +
    Object.entries(obj)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")
  );
};
